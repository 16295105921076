/* eslint-disable @typescript-eslint/no-unused-vars */
// Them
import React, {
  useState, useContext, useEffect, useCallback, useRef,
} from 'react';
import { DefaultTheme, makeStyles } from '@mui/styles';
import {
  Box, Paper, Typography, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';

// Us
import { getChatMessages, sendPrompt, useChatbot } from '@store/chatbotSlice';
import { MessageType } from '@models/ChatMessage';
import { reactPathTS } from '@components/utilities/Paths';
import { useAppDispatch } from '@store/hooks';
import { GeneralUXContext } from '@components/layout/GeneralUXContext';
import { hashString } from '@components/utilities/general';
import ChatBubble from './implementation/ChatBubble';

interface StyleContext {
  expandChatbot: boolean;
}

const useStyles = makeStyles<DefaultTheme, StyleContext>(() => ({
  root: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    width: '90%',
    maxWidth: (ctx: StyleContext) => (ctx.expandChatbot ? '90%' : 400),
    height: (ctx: StyleContext) => (ctx.expandChatbot ? '90%' : '60%'),
    zIndex: 1300,
    backgroundColor: '#f5f5f5',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    overflow: 'hidden',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '40px 1fr auto auto auto',
    alignItems: 'center',
    padding: '10px 15px',
    backgroundColor: '#528BBC', // '#1976d2',
    color: '#fff',
  },
  messages: {
    flex: 1,
    padding: '15px 15px',
    overflowY: 'auto',
  },
  inputContainer: {
    display: 'flex',
    padding: '10px 15px',
    backgroundColor: '#fff',
  },
  textField: {
    flex: 1,
    marginRight: 10,
  },
  sendButton: {
    alignSelf: 'flex-end',
  },
}));

function scrollLastUserMessageIntoView(elem: HTMLElement | null) {
  // Note: using a query of '.userBubble:last-child' doesn't seem to work in Chrome so we have to
  // find the last child ourselves.
  const userBubbles = elem?.querySelectorAll('.userBubble');
  if (userBubbles && userBubbles.length > 0) {
    userBubbles[userBubbles.length - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

export default function ChatWindow() {
  const {
    openChatbot, expandChatbot, setOpenChatbot, setExpandChatbot,
  } = useContext(GeneralUXContext);
  const chatContainerRef = useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();
  const classes = useStyles({ expandChatbot });
  const chatMessages = useChatbot();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [pendingMessage, setPendingMessage] = useState('');

  const handleSend = useCallback(() => {
    if (message.trim() === '') return;
    setPendingMessage(message);
    setMessage('');
    setLoading(true);
  }, [message]);

  useEffect(() => {
    if (pendingMessage && loading) {
      const sendMessage = async () => {
        try {
          await sendPrompt(dispatch, pendingMessage);
        } finally {
          setLoading(false);
          setPendingMessage('');
          scrollLastUserMessageIntoView(chatContainerRef.current);
        }
      };
      sendMessage();
    }
  }, [dispatch, pendingMessage, loading]);

  useEffect(() => {
    if (pendingMessage && loading && chatMessages?.length && chatMessages[chatMessages.length - 1].type === MessageType.User) {
      scrollLastUserMessageIntoView(chatContainerRef.current);
    }
  }, [dispatch, pendingMessage, loading, chatMessages]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    } else if (event.key === 'Enter' && event.shiftKey) {
      // Allow Shift+Enter to create a new line
      setMessage((m) => m);
    }
  };

  return openChatbot ? (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        <img
          src={`${reactPathTS}Pathfinder-face-teal.svg`}
          alt="Pathfinder"
          width={32}
          height={32}
        />
        <Typography variant="h6">Pathfinder</Typography>
        <IconButton size="small" color="inherit" onClick={() => getChatMessages(dispatch, true)}>
          <Tooltip title="Start a new chat">
            <RestartAltIcon />
          </Tooltip>
        </IconButton>
        <IconButton size="small" color="inherit" onClick={() => setExpandChatbot(!expandChatbot)}>
          <Tooltip title={`${expandChatbot ? 'Collapse' : 'Expand'} window`}>
            {expandChatbot ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
          </Tooltip>
        </IconButton>
        <IconButton size="small" color="inherit" onClick={() => setOpenChatbot(false)}>
          <Tooltip title="Close Pathfinder">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </Box>
      <Box className={classes.messages} ref={chatContainerRef}>
        {chatMessages.map((cm) => (
          <ChatBubble message={cm.content} isUser={cm.type === MessageType.User} key={hashString(cm.content)} />
        ))}
        {loading && <img src={`${reactPathTS}typing.gif`} alt="(Typing...)" style={{ height: '1rem' }} />}
      </Box>
      <Box className={classes.inputContainer}>
        <TextField
          className={classes.textField}
          id="outlined-multiline-flexible"
          placeholder="Ask for help here"
          multiline
          maxRows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyDown}
          disabled={loading}
        />
        <IconButton color="primary" sx={{ alignSelf: 'center', width: 40, height: 40 }} disabled={loading || message.trim() === ''}>
          <Tooltip title="Ask Pathfinder">
            <Box sx={{ height: 24 }}>
              <SendIcon />
            </Box>
          </Tooltip>
        </IconButton>
      </Box>
    </Paper>
  ) : (<></>);
}
